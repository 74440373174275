// export class Location {
//   locationId: number;
//   name: string;
//   fullLocationId: string;
//   rules: any;
//   allowEveryCardInSystem: number;
//   allowEveryCard: number;
//   locationLocationGroups: {
//     $type: string;
//     $values: Array<any>;
//   };
//   permissions: {
//     $type: string;
//     $values: Array<any>;
//   };

//   constructor() {}
// }
import { CardPositions, LocationLocationGroups, Permissions, Controller } from "../controller.model";


export interface LocationControllers {
  $type: string;
  $values: Controller[];
}

export interface Tag {
  locationTagId?: number,
  locationId?: number,
  tag: string,
  location?: any
}

export interface LocationTags {
  $type: string;
  $values: Tag[];
}

export class Location {
  locationId: number;
  locationTypeId: number;
  name: string;
  fullLocationId: string;
  rules: any;
  allowEveryCardInSystem: number;
  allowEveryCard: number;
  projectSubobjectId: number;
  isRented: boolean;
  restoreDefaultOnCheckout: boolean;
  isOnline: boolean;
  joinActive: boolean;
  joinType: boolean;
  position: number;
  projectSubobject: any;
  locationType: number;
  cardPositions: CardPositions;
  locationLocationGroups: LocationLocationGroups;
  permissions: Permissions;
  controllers: LocationControllers;
  locationTags: LocationTags;
  update?: boolean;

  constructor () {}

  public static isGuestRoom(location: Location) {
    return location?.locationTypeId === 1
  }

  public static isAccessControl(location: Location) {
    return location.locationTypeId === 2
  }

  public static isIOCommonArea(location: Location) {
    return location.locationTypeId === 3
  }

  public static isHvacCommonArea(location: Location) {
    return location.locationTypeId === 4
  }

  public static getLocationObject(location: Location) {
    return location.fullLocationId.split('/')[0]
  }

  public static getLocationSubObject(location: Location) {
    return location.fullLocationId.split('/')[1]
  }

  public static getLocationZone(location: Location) {
    return location.fullLocationId.split('/')[2]
  }



}
