import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

type LogData  = {
  controllerDesignation: string;
  propertyType: string;
  timestamp: string;
  value: number;
}

@Pipe({
  name: 'getLogValuePropertyName',
})
export class GetLogValuePropertyNamePipe implements PipeTransform {

  transform(logData: LogData, controller: Controller): string {
    const prop = controller.controllerProperties.$values.find((prop)=> {
      return Number(prop.type) === Number(logData.propertyType)
    })

    if (prop.name.includes('|')) {
      return prop.name.split('|')[0] + ' ' + prop.name.split('|')[1];
    }

    return prop.name?prop.name:'Unknown property';
  }

}
