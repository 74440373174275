import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';
import { IPropDictionary } from '../services/prop-dictionary.service';

@Pipe({
  name: 'getDictionaryValue'
})
export class GetDictionaryValuePipe implements PipeTransform {

  transform(prop: Property, dictionary: IPropDictionary, updated?: Date): {text: string, iconOn: boolean, textShort: string} {
    if (!prop) {
      return
    }

    // LG Unit Status
    // -1 : LG status Undefined
    // 0: LG status OK
    // x: LG Error x

    if (Property.isLgUnitStatus(prop) && prop.value.toString() !== '0' && prop.value.toString() !== '-1') {
      return {
        iconOn:false,
        text: `LG Error ${prop.value?prop.value:'undefined'}`,
        textShort: `LG Error ${prop.value?prop.value:'undef.'}`
      }
    }


    const dictionaryEntry = dictionary.entries.find((de)=> {
      return prop.value.toString() === de.value
    })
    if (!dictionaryEntry) {
      return dictionary.noEntryFound
    }
    return dictionaryEntry
  }

}
