import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, debounceTime, distinctUntilChanged, interval, switchMap, take, takeWhile } from 'rxjs';
import { ApiService } from '../http/api.service';
import { IMqttMessage, MqttConnectionState, MqttService } from 'ngx-mqtt';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { ENVIRONMENT_MQTT_SERVICE_OPTIONS, USE_DALI } from 'src/environments/environment';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiHvacModesService } from 'src/app/modules/groups/services/api-hvac-modes.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { LocationsFilter } from '../models/locations-filter.model';
import { ModalController, ToastController } from '@ionic/angular';
import { ConvertLoadingComponent } from 'src/app/shared/components/convert-loading/convert-loading.component';
import { MqttMessageQueueService } from './mqtt-message-queue.service';
import { CardReaderService } from '../services/card-reader.service';


export interface DriverQueue {
  driverDesignation: string;
  queueStatus: number;
}

@Injectable({
  providedIn: 'root'
})

export class MqttProjectService {

  mqttSubscription = new Subscription();
  mqttCliSubscriptin = new Subscription();
  daliMqttSubscription = new Subscription();
  startMqttReconnect = false;
  mqttInterval: Subscription;
  syncInProgress = new Subject<number>();
  disconectedBecauseTabInactive$ = new BehaviorSubject<boolean>(false)
  needUpdateBecauseOfMqttReconect$ = new Subject<boolean>;

  convertProjectTimeout: NodeJS.Timeout;
  convertProjectTimeoutInterval = 60000;
  syncModalOpen = false;
  private convertLoadingStatus$ = new BehaviorSubject<number>(null)

  private driverQueueStatus$ = new BehaviorSubject<DriverQueue[]>([])
  private syncStatus$ = new BehaviorSubject<DriverQueue[]>([])

  constructor(
    private apiService: ApiService,
    private mqttService: MqttService,
    private projectService: ProjectService,
    private alarmAlertsService: AlarmAlertsService,
    private apiDaliProjectService: ApiDaliProjectService,
    private daliProjectService: DaliProjectService,
    private apiHvacModeService: ApiHvacModesService,
    private filterService: FiltersService,
    private apiProjectService: ApiProjectService,
    private loadingModalController: ModalController,
    private injector: Injector,
    private toastController: ToastController,
    private mqttMessageQueueService: MqttMessageQueueService,
    private cardReaderService: CardReaderService
    ) {}

  initMqttForProject() {
    this.subscribeToMqtt();
    this.subscribeToMqttCli();
    this.mqttMessageQueueService.setIgnoreMqttMessages(false);
    this.mqttMessageQueueService.processQueue();
    if (USE_DALI) {
      this.subscribeToDaliMqtt();
    }

    this.mqttService.onConnect.subscribe( () => {
    this.mqttMessageQueueService.setIgnoreMqttMessages(false);
    this.mqttMessageQueueService.emptyMqttQueue();
    this.mqttMessageQueueService.processQueue();
    if (this.startMqttReconnect) {
        this.cardReaderService.subscribeToReaderTopics();
        this.subscribeToMqtt();
        this.subscribeToMqttCli();
        this.needUpdateBecauseOfMqttReconect$.next(true);
        if (USE_DALI) {
          this.subscribeToDaliMqtt();
        }

      //get projects again for missed changes while mqtt was offline
      if (USE_DALI) {
        this.apiDaliProjectService.getProject().subscribe();
      }
      this.apiProjectService.getAllLocationsFat().subscribe();
      }
      if (this.mqttInterval) {
        this.mqttInterval.unsubscribe();
      }
      this.startMqttReconnect = false;
      // console.log('mqtt connected');
    })

    this.mqttService.onEnd.subscribe( () => {
      if(this.mqttService.state.subscribe((state) => {
        if(state === MqttConnectionState.CONNECTED){
          this.unsubscribeFromMqtt();
          this.cardReaderService.unsubscribeFromReaderTopic();
        }
      }))
      // throws error "mqtt check disconneting..."
      // this.unsubscribeFromMqtt();
      // this.cardReaderService.unsubscribeFromReaderTopic();
      this.startMqttReconnect = true
      // console.log('mqtt disconected');
      if ( (!this.mqttInterval && !this.disconectedBecauseTabInactive$.getValue()) ||
          (this.mqttInterval && this.mqttInterval.closed && !this.disconectedBecauseTabInactive$.getValue())) {
        this.mqttInterval = interval(5000).pipe(takeWhile(() => this.startMqttReconnect)).subscribe(() => {
        // console.log('trying to reconnect mqtt');
        this.mqttService.connect(ENVIRONMENT_MQTT_SERVICE_OPTIONS);
      });
      }
    })
  }

  subscribeToMqtt() {
    let hvacModeRequestActive = false;
    this.apiService.getBaseMqttSubscription().subscribe((mqttBase) => {
      const alarmRegex: RegExp = new RegExp(mqttBase + '/alarm/.+')
      this.mqttSubscription = this.mqttService.observe(`${mqttBase}/#`).subscribe((message: IMqttMessage) => {
        if (alarmRegex.test(message.topic)) {// ALARMS. Msg format : 'date|custom msg|controller designation|lvl'
          this.alarmAlertsService.handleMqttAlarms(message);

          const alarmTypeId = message.topic.split('/').at(-1)
          if (alarmTypeId.toString() === '12' /* message.payload.toString().includes('Controller offline') */) {
            const data =  message?.payload?.toString().split('|')[2];
            const controllerDesignation = data?.split(' ')[0]
            if (controllerDesignation) {
              this.projectService.updateControllerIsOnlineStatus(controllerDesignation, false);
            }

          } else if(alarmTypeId.toString() === '13' /* message.payload.toString().includes('Controller online') */) {
            const data =  message.payload.toString().split('|')[2];
            const controllerDesignation = data?.split(' ')[0]
            if(controllerDesignation) {
              this.projectService.updateControllerIsOnlineStatus(controllerDesignation, true);
            }
          }
        } else if(message.topic.toLowerCase().includes('isrented')) { // location is RENTED
          this.updateLocationIsRentedStatus(message, mqttBase)
        }
         else if(message.topic.toLowerCase().includes('joinactive')) { // location is JOINED
          this.updateLocationIsJoinedStatus(message, mqttBase)
        }  else if (message.topic.toLowerCase().includes('isonline')) { //location is ONLINE
          this.updateLocationIsOnlineStatus(message, mqttBase)
        }
        else if(message.topic.toLowerCase().includes('hvacmodestatuschange')) { // HVAC MODE STATUS CHANGE
          this.filterService.getFilters().pipe(take(1)).subscribe((filters: LocationsFilter) => {
            let objectId;
            if (filters.objects.length === 0) {
              objectId = '0';
            } else {
              objectId = filters.objects[0]
            }
            if (!hvacModeRequestActive) {
              hvacModeRequestActive = true
              setTimeout( () => {
                this.apiHvacModeService.getActiveHvacMode(objectId).subscribe( () => {
                  hvacModeRequestActive = false;
                })
              },10000)
            }
          })
        } else if (message.topic.toLowerCase().includes('projectconvert')) {
          this.handleProjectConvertInProgressMqttMessage(message);
        } else if (message.topic.toLowerCase().includes('cardsyncactive')){
          this.projectService.handleCardSyncActive(message);
        } else if (message.topic.toLocaleLowerCase().includes('lastfrontendupdate')) {
          this.onFrontendUpdateMessage(message.payload.toString())
        }
      });
    });
  }

  onFrontendUpdateMessage(dateTime: string){
    const lastFrontUpdate = localStorage.getItem('lastFrontendUpdate');
    if (lastFrontUpdate) {
      if (lastFrontUpdate != dateTime) {
        const refresh_token = localStorage.getItem('refresh_token');
        const access_token = localStorage.getItem('access_token');
        localStorage.clear()
        localStorage.setItem('lastFrontendUpdate', dateTime)
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        window.location.reload();
      }
    } else {
      localStorage.setItem('lastFrontendUpdate', dateTime)
    }
  }

 async handleProjectConvertInProgressMqttMessage(message: IMqttMessage) {
    const loadingPercentageValue = Number(message.payload.toString().split('|')[1])
    this.setConvertLoadingStatus(loadingPercentageValue)
    const mController = this.injector.get(ModalController)
    // const topModal: HTMLIonModalElement = await mController.getTop()
    clearTimeout( this.convertProjectTimeout);
    const modalClassName = 'convert-project-loading-modal';
    if (this.syncModalOpen && loadingPercentageValue < 100) {
      // modal is already opened
      this.setProjectConvertTimeout()
      return
    } else if (this.syncModalOpen && loadingPercentageValue >= 100) {
      // close modal on loading value 100  (or lager)
      mController.getTop().then(v => v ? this.loadingModalController.dismiss() : null);
    } else if (loadingPercentageValue < 100){
      this.syncModalOpen = true;
      // open modal on value smaller than 100
      const modal = await this.loadingModalController.create({
        component: ConvertLoadingComponent,
        backdropDismiss: false,
        showBackdrop: true,
        cssClass: modalClassName,
      });
      this.setProjectConvertTimeout()
      modal.onDidDismiss().then( () => {
        this.syncModalOpen = false;
      })
      return await modal.present();
    }
  }

  setProjectConvertTimeout() {
    this.convertProjectTimeout = setTimeout(async() => {
      this.loadingModalController.dismiss();
      const toast = await this.toastController.create({
        message: 'Convert project timed out',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
          }
        ]
      });
      await toast.present();
    }, this.convertProjectTimeoutInterval);
  }

  subscribeToDaliMqtt() {
    this.apiDaliProjectService.getBaseDaliMqttSubscription().subscribe((daliMqttBase) => {
      this.daliMqttSubscription = this.mqttService.observe(`${daliMqttBase}/#`).subscribe((message: IMqttMessage) => {
        const daliType = message.topic.split('/')[2];
        const daliId = message.topic.split('/')[4];
        this.daliProjectService.updateDaliProjectByMqtt(daliType, daliId, message.payload.toString());
      });
    })
  }

  updateLocationIsRentedStatus(message: IMqttMessage, mqttBase: string) {
    let locationId = message.topic.substring(mqttBase.length+1, message.topic.length);
    locationId = locationId.split('/')[1];

    const newValue: string = message.payload.toString().split('|')[1];
    let newIsRentedValue: boolean;
    if (newValue?.toLowerCase() === 'true' || Number(newValue)>0) {
      newIsRentedValue = true;
    } else if (newValue?.toLowerCase() === 'false' || Number(newValue) === 0) {
      newIsRentedValue = false;
    }
    this.projectService.updateLocationIsRentedStatus(locationId, newIsRentedValue);
  }

   updateLocationIsJoinedStatus(message: IMqttMessage, mqttBase: string) {
    let locationId = message.topic.substring(mqttBase.length+1, message.topic.length);
    locationId = locationId.split('/')[1];

    const newValue: string = message.payload.toString().split('|')[1];
    let newIsJoinedValue: boolean;
    if (newValue?.toLowerCase() === 'true' || Number(newValue)>0) {
      newIsJoinedValue = true;
    } else if (newValue?.toLowerCase() === 'false' || Number(newValue) === 0) {
      newIsJoinedValue = false;
    }

    this.projectService.updateLocationIsJoinedStatus(locationId, newIsJoinedValue);
  } 

  updateLocationIsOnlineStatus(message: IMqttMessage, mqttBase: string) {
    let locationId = message.topic.substring(mqttBase.length+1, message.topic.length);
    locationId = locationId.split('/')[1];
    const newValue: string = message.payload.toString().split('|')[1];
    let newIsOnlineValue: boolean;
    if (newValue?.toLowerCase() === 'true' || Number(newValue)>0) {
      newIsOnlineValue = true;
    } else if (newValue?.toLowerCase() === 'false' || Number(newValue) === 0) {
      newIsOnlineValue = false;
    }
    this.projectService.updateLocationIsOnlineStatus(locationId, newIsOnlineValue);
  }



  subscribeToMqttCli() {
    let hvacModeRequestActive = false;
    this.mqttCliSubscriptin = this.mqttService.observe('cli/#').subscribe((message: IMqttMessage) => {
      if (message.topic.includes('messagequeue')) {
        const loadingPercentageValue = Number(message.payload.toString().split('|')[1])
        let driverQueue = <DriverQueue>{};
        driverQueue.queueStatus = loadingPercentageValue
        driverQueue.driverDesignation = message.topic.split('/')[1] + '/' + message.topic.split('/')[2]
        this.setDriverQueue(driverQueue)
      }
      if(message.topic.includes('synccardactions')) {
        const loadingPercentageValue = Number(message.payload.toString().split('|')[1])
        let driverQueue = <DriverQueue>{};
        driverQueue.queueStatus = loadingPercentageValue
        driverQueue.driverDesignation = message.topic.split('/')[1] + '/' + message.topic.split('/')[2]
        this.setCardsSyncQueue(driverQueue)
      }
      if(message.topic.toLowerCase().includes('pollresponse') || message.topic.toLowerCase().includes('controllerresponse')) {
        // ignore pollresponse and controller response mqtt messages
        return;
      }
      if (!message.topic.includes('messagequeue') && !message.topic.includes('timeout') && !message.topic.includes('responseerror')) {
        this.mqttMessageQueueService.addMqttMessageToQueue(message);
        // this.projectService.updateFilteredProjectByMqtt(message);
        //  this.projectService.updateFullProjectByMqtt(message)
        this.projectService.singleControllerMessage$.next(message);
      }
      if(message.topic.includes('heatingcooling')) {

        this.filterService.getFilters().pipe(take(1)).subscribe((filters: LocationsFilter) => {
          let objectId;
          if (filters.objects.length === 0) {
            objectId = '0';
          } else {
            objectId = filters.objects[0]
          }
          if (!hvacModeRequestActive) {
            hvacModeRequestActive = true
            setTimeout( () => {
              this.apiHvacModeService.getActiveHvacMode(objectId).subscribe( () => {
                hvacModeRequestActive = false;
              })
            },10000)
          }
        })
      }
    })
  }

  getConvertLoadingStatus() {
    return this.convertLoadingStatus$;
  }

  setConvertLoadingStatus(newValue: number) {
    this.convertLoadingStatus$.next(newValue)
  }

  setDriverQueue(driverQueue: DriverQueue) {
   let driverQueueStatus = this.driverQueueStatus$.getValue()
   const target = driverQueueStatus.find( element => element.driverDesignation == driverQueue.driverDesignation)

   if (target) {
    target.queueStatus = driverQueue.queueStatus
   } else {
    driverQueueStatus.push(driverQueue)
   }

   this.driverQueueStatus$.next(driverQueueStatus);
  }

  getDriverQueue() {
    return this.driverQueueStatus$;
  }

  setCardsSyncQueue(driverQueue: DriverQueue) {
    let cardSyncQueueStatus = this.syncStatus$.getValue()
    const target = cardSyncQueueStatus.find( element => element.driverDesignation == driverQueue.driverDesignation)

    if (target) {
     target.queueStatus = driverQueue.queueStatus
    } else {
      cardSyncQueueStatus.push(driverQueue)
    }

    this.syncStatus$.next(cardSyncQueueStatus);
  }

  getCardsSyncQueue() {
    return this.syncStatus$;
  }


  unsubscribeFromMqtt() {
    if (this.mqttCliSubscriptin) {
      this.mqttCliSubscriptin.unsubscribe()
      this.mqttCliSubscriptin = new Subscription()
    }
    if (this.mqttSubscription) {
      this.mqttSubscription.unsubscribe();
      this.mqttSubscription = new Subscription();
    }
    if (this.daliMqttSubscription) {
      this.daliMqttSubscription.unsubscribe();
      this.daliMqttSubscription = new Subscription()
    }
    this.mqttMessageQueueService.setIgnoreMqttMessages(true);
  }
}
