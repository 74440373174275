import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { PopoverController } from '@ionic/angular';
import { CardsService } from 'src/app/modules/users/services/cards.service';

@Component({
  selector: 'app-date-time-inline',
  templateUrl: './date-time-inline.component.html',
  styleUrls: ['./date-time-inline.component.scss']
})
export class DateTimeInlineComponent implements OnInit {
  @ViewChild('dtFrom') dtFrom: OwlDateTimeComponent<any>;
  @ViewChild('dtTo') dtTo: OwlDateTimeComponent<any>;
  @Input() selectedDateTime: Date[];
  maxDate = this.cardsService.cardsMaxEndDate;

  constructor(private popoverController: PopoverController,
    private cardsService: CardsService
  ) { }

  ngOnInit(): void {}

  acceptData() {
    this.popoverController.dismiss(this.selectedDateTime)
  }
}
